/**
 * Device 新增
 * @type {{}}
 */
import Vue from "vue";
import { Message,Modal,Breadcrumb,BreadcrumbItem,Progress,Select,Option,Input,Icon,Button,Cascader,Tabs,TabPane ,Poptip,Table,Spin,Carousel,CarouselItem,Tooltip,InputNumber} from 'view-design';
import {
    getDeviceInfoIdService,
    getDeviceModelService,
    getManufacturerService,
    addManufacturerService,
    getNameManufacturerService,
    addDeviceModelService,
    getIdManufacturerService,
    getDeviceInfoService,
    updateDeviceInfoService,
    addDeviceDataService,
    delDeviceDataService,
    updateDeviceDataService,
    addFailureService,
    delFailureDataService,
    addReasonService,
    delReasonDataService,
    editFailureService,
    editReasonService,
    getFailureMoveService,
    getReasonMoveService,
    getFailureDownLoadService,
    addFailureUpLoadService,
    getFailureExportService,
    addStructuralService,
    editStructuralService,
    delStructuralService,
    updateElectricListNameService,
    updateElectricStatusService,
    delElectricalListService,
    upgradeStructuralService,
    updateDeviceInfoIntroService,
    updateDeviceInfoPrincipleService,
    editDeviceModelService,
    deleteDeviceModelService,
    deleteManufacturerService,
    editManufacturerService,
    addPdfElectricalService,
    convertPdfElectricalService,
    editElectricalAtlasRenameService,
    getPdfElectricalProgressService,
    delDeviceAtlasService,
    getStructuralDeviceIdService,
    getStructuralInfoIdService,
    updateDeviceInfoParamService,
    deviceDataColumns,
    faultDataColumns,
    reasonDataColumns,
    structuralElementColumns,
    parameterColumns,
    deviceTabs,
} from "@/service/device-info-service";
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('BreadcrumbItem', BreadcrumbItem);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Button', Button);
Vue.component('Cascader', Cascader);
Vue.component('Modal', Modal);
Vue.component('Tabs', Tabs);
Vue.component('TabPane', TabPane);
Vue.component('Table', Table);
Vue.component('Spin', Spin);
Vue.component('Carousel', Carousel);
Vue.component('CarouselItem', CarouselItem);
Vue.component('Progress', Progress);
Vue.component('Tooltip', Tooltip);
Vue.component('Poptip', Poptip);
Vue.component('InputNumber', InputNumber);
import { mapMutations, mapState } from "vuex";
import { downloadFile, renderSize,onRandomPlatform,swapArray,saveImage,compressImage } from "@/utils/tools";
import { systemUploadType } from '@/service/system-service'
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import { onSysncUploadFiles } from '@/utils/request-function'
import ViewImage from '@/components/ViewImage/viewImage.vue'
const DeviceInfo = {
    name:'DeviceInfo',
    components:{ ViewImage },
    computed: {
        ...mapState({
            classifyMenuArray: state => state.system.classifyMenuArray, //分类菜单数据
            selectedMenuId: state => state.system.selectedMenuId, //选中的菜单Id
            deviceInfo: state => state.system.deviceInfo, //设备id
            tabId:state => state.system.tabId, //tabId
            menuVisible: state => state.system.menuVisible, //菜单显示状态
            structuralId: state => state.system.structuralId, //结构部件id
        }),
        /**
         * 文件格式验证
         * @returns {function(*=, *): boolean}
         */
        onFileFormatCheck(){
            return (value) =>{
                let status
                let suffixIndex = value.lastIndexOf('.')
                let suffix=value.substring(suffixIndex+1).toUpperCase();
                if(suffix!="BMP"&&suffix!="JPG"&&suffix!="JPEG"&&suffix!="PNG"&&suffix!="GIF"){
                    status = false
                }else{
                    status = true
                }
                return status
            }
        }
    },
    data() {
        return {
            renderSize:renderSize, //文件大小转换
            submitLoading:false, //提交等待
            drawing:false, //是否绘制
            failureDownLoadLoading:false, //下载模版数据loading
            failureExportLoading:false, //导出故障分析数据loading
            imageLoading:false, //图片loading
            deviceDataColumns:deviceDataColumns, //资料文件显示字段
            faultDataColumns:faultDataColumns, //故障分析显示字段
            parameterColumns:parameterColumns,
            structuralElementColumns:structuralElementColumns, //结构部件显示字段
            reasonDataColumns:reasonDataColumns, //可能的原因显示字段
            deviceTabs:deviceTabs, //标签页数据
            deviceModelVisible:false, //新增设备型号可显示
            deviceModelInfo:'',
            deviceFactureInfo:'',
            structuralInfo:'', //结构部件的信息数量
            deviceRename:'',
            manufacturerVisible:false, //新增设备厂家可显示
            addFaultVisible:false, //新增故障可显示
            faultStatus:false, //新增故障状态
            structuralStatus:false, //新增结构部件状态
            structuralVisible:false, //结构部件可显示
            reasonVisible:false, //可能的原因可显示
            reasonLoading:false, //可能的原因loading
            reasonLookVisible:false, //可能的原因查看可显示
            reasonBigLookVisible:false, //可能的原因查看大图可显示
            imageBigLookVisible:false, //图册查看大图可显示
            exportVisible:false, //导入弹框可显示
            pdfModalVisible:false, //pdf导入弹框可显示
            pdfAddVisible:false,
            pdfPageStar:1,
            pdfPageEnd:1,
            pdfName:'',
            pdfUploadStatus:false,
            faultTableHeight:0, //表格高度
            parameterHeight:0,
            imageBigArray:[], //图册数据
            imageObj:{},
            imageName:'',
            viewImageVisible:false,
            imageWidth:0,
            imageHeight:0,
            imageLeft:0,
            imageTop:0,
            currentX:0, //记录落点的x坐标
            currentY:0, //记录落点的Y坐标
            offsetWidth:0, //记录滚动内容宽度
            imageBigNumber:0,
            imageBigActive:0, //大图定位值
            deviceTabsNumber:1,
            imageRatio:100,
            complete:0, //上传进度
            completePdf:0,
            speedList:[], //上传文件临时存储
            speedVisible:false, //上传进度可显示
            speedSingleVisible:false, //单文件上传进度可显示
            faultsVisible:false, //故障分析上传进度可显示
            faultsSpeed:{}, //故障分析上传文件临时存储
            faultsCancel:false, //故障分析取消按钮
            faultsMsg:'', //故障分析提示
            reasonStatus:false, // 解决方案弹框title状态
            failureAnalysisFaultId:'', //故障描述id
            imageSrc:'', //上传缩略图
            fileHandleVisible:false, //上传操作弹框可显示
            deviceModelArray:[], //获取所有设备型号
            deviceModelSelect:[], //选中的设备型号
            deviceBtnVisible:false, //设备按钮状态
            deviceAddVisible:false, //新增数据状态
            deviceModelValue:'', //查询设备型号
            deviceFactureValue:'', //查询生产厂家
            manufacturerArray:[], //获取所有生产厂家
            deviceFactureSelect:[], //选中的上产厂家
            deviceInfoObj:{}, //设备信息数据对象
            deviceInfoArray:[], //设备信息数据集合
            deviceStructuralArray:[], //结构部件列表数据
            deviceInfoLoading:false, //设备信息loading
            deviceModelManufacturerArray:[], //根据设备型号拉取的生产厂家
            deviceElectricalName:'', //电气图册列表名称
            deviceSelectPart:{}, //选中的结构部件
            devicePartType:'', //设备分类
            devicePartTypeList:[], //设备分类数据
            deviceInfoStatus:false, //设备简介编辑状态
            devicePrincipleLoading:false, //设备原理loading
            devicePrincipleEditLoading:false,
            deviceInfoContent:'', //设备简介信息
            deviceInfoContentLoading:false,
            principleKey:0,
            parameterStatus:false,
            parameterLoading:false,
            deviceListParams:{ //提交的数据集
                checkInput:[],//选择的数据列表项
            },
            newlyParams:{  //保存数据集
                deviceType:[], //选择的设备分类
                deviceName:'', //设备名称
                deviceModel:'', //选择的设备型号
                manufacturer:'', //选择的生产厂家
                files:'', //选择的图片流
                filesUrl:'',
                fileName:'',
            },
            deviceParams:{ //新增设备型号数据集
                deviceName:'', //设备型号名称
                manufacturer:[], //生产厂家
            },
            manufacturerParams:{ //新增生产厂家数据集
                manufacturerName:'',
            },
            faultParams:{ //故障分析数据集
                id:'',
                code:'', //故障名称
                describe:'', //故障描述
            },
            structuralParams:{ //结构部件数据集
                id:'',
                partsName:'',
                fileUrl:'',
                resourceName:'',
                partsBlurb:'',
                parentId:'',
                partsModel:'',
            },
            reasonParams: { //可能原因数据集
                reason:'',
                solution:'',
            },
            faultsList:[], //故障分析数据集
            reasonArray:{}, //可能的原因数据集
            pdfParams:{
                id:'',
                filePageCount:1,
                fileType:'',
            }
        }
    },
    created() {
        this.SET_STRUCTURAL_ID('')
        this.deviceTabsNumber = this.tabId
        this.onStructuralDeviceId().then()
        this.onDeviceInfo().then(()=>{
            if(this.tabId === 6 && this.structuralId === ''){
                this.onDeviceModelAll().then()
                this.onManufacturerAll().then()
            }
            if(this.structuralId!==''){
                this.onStructuralInfoIdService().then()
            }else{
                this.onDeviceInfoIdService().then()
            }
        })
        /**
         * 进度条数值
         */
        $event.$on(EVENT_NAME.UPLOAD_PROGRESS_COMPLETE, (n) => {
            this.complete = n
            this.completePdf +=1
        })
        /**
         * 可能的原因可显示
         */
        $event.$on(EVENT_NAME.REASON_MODAL_VISIBLE, (obj) => {
            this.failureAnalysisFaultId = obj.id
            switch (obj.name){
                case 'add':
                    this.reasonVisible = true
                    this.reasonStatus = true
                    this.reasonArray = {
                        reason:'',
                        solutionList:[
                            {
                                solution:'',
                                solutionResourcesFileUrl:'',
                                visible:false,
                            }
                        ]
                    }
                    break;
                case 'edit':
                    this.reasonArray = obj.reasons
                    this.reasonVisible = true
                    this.reasonStatus = false
                    break;
                case 'look':
                    this.reasonArray = obj.reasons
                    this.reasonVisible = false
                    this.reasonLookVisible = true
                    break;
                case 'del':
                    this.onDelReason(obj.reasonId)
                    break;
                case 'move':
                    this.onReasonMoveService(obj.reasonId,obj.moveType).then()
                    break;
            }
        });
        this.faultTableHeight = window.innerHeight - 180
        this.parameterHeight = window.innerHeight - 130
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.faultTableHeight = window.innerHeight - 180
                this.parameterHeight = window.innerHeight - 130
            })()
        };
    },
    methods: {
        ...mapMutations('system',['SET_TAB_ID','SET_DEVICE_INFO','SET_STRUCTURAL_INFO','SET_ELECTRICAL_ID','SET_STRUCTURAL_ID']),
        /**
         * 获取contenteditable数据
         */
        getData() {
            this.deviceRename = this.$refs.editor.innerHTML
        },
        /**
         * 点击全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectAll(selection) {
            this.selectAllCancel = selection
            for(let key in selection){
                let flag = true
                for(let index in this.deviceListParams.checkInput){
                    if(selection[key].id === this.deviceListParams.checkInput[index]){
                        flag = false
                    }
                }
                if(flag){
                    this.deviceListParams.checkInput.push(selection[key].id)
                }
            }
        },
        /**
         * 点击取消全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectAllCancel() {
            let selection = this.selectAllCancel
            for(let key in selection){
                for(let index in this.deviceListParams.checkInput){
                    if(selection[key].id === this.deviceListParams.checkInput[index]){
                        this.deviceListParams.checkInput.splice(index,1);
                    }
                }
            }
        },
        /**
         * 选中某以一项时触发
         * selection: 以选项数据
         * row: 刚选择的数据
         * some() 方法用于检测数组中的元素是否满足指定条件
         * 注意： some() 不会对空数组进行检测。
         * 注意： some() 不会改变原始数组。
         * @param selection，row
         */
        onSelect(selection,row) {
            let mediaList = this.deviceListParams.checkInput.some((e)=>{
                return e.id === row.id
            })
            if(!mediaList){
                this.deviceListParams.checkInput.push(row.id)
            }
        },
        /**
         * 取消选中某一项时触发
         * selection: 以选项数据
         * row: 取消选择的项数据
         * 遍历当前选中数组 如有相同则在当前数组的指定位置进行删除
         * @param selection，row
         */
        onSelectCancel(selection,row) {
            for(let key in this.deviceListParams.checkInput){
                if(this.deviceListParams.checkInput[key] === row.id){
                    this.deviceListParams.checkInput.splice(key,1);
                }
            }
        },
        /**
         * 验证excel格式/拉取参数
         * @param e
         * @returns {Promise<void>}
         */
        async onSignUpLoad(e) {
            let files = e.target.files[0]
            this.$refs.uploadFault.value = ''
            if(files){
                if(files.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && files.type !== 'application/vnd.ms-excel application/x-excel'){
                    Message.warning('只能上传固定格式的Excel文件，且不超过8M')
                    return;
                }
                if(files.size > 8*1024*1024){
                    Message.warning('只能上传8M以内的Excel文件！')
                    return;
                }
                this.faultsSpeed = files
                this.onFailureUpLoadService(files).then()
            }
        },
        /**
         * 点击上传
         */
        choiceImg(){
            this.$refs.uploadFault.dispatchEvent(new MouseEvent('click'))
        },
        /**
         * 切换tabs
         * @param n
         */
        onTabsSwitch(n){
            if(n === this.deviceTabsNumber){
                return
            }
            this.deviceTabsNumber = n
            this.SET_TAB_ID(n)
            this.deviceListParams.checkInput = []
            this.onDeviceInfo().then(()=>{
                if(this.tabId === 6){
                    this.onDeviceModelAll().then()
                    this.onManufacturerAll().then()
                }
            })
        },
        /**
         * 新增设备型号验证
         */
        onDeviceModelCheck(){
            if(this.deviceParams.deviceName === ''){
                Message.warning('请输入设备型号！')
                return
            }
            if(this.deviceModelSelect.some((item)=>{ return item.name === this.deviceParams.deviceName })){
                Message.warning('信息重复，请重新输入！')
                return
            }
            // if(this.deviceParams.manufacturer.length === 0){
            //     Message.error('请选择生产厂家！')
            //     return
            // }
            this.onDeviceModel().then()
        },
        /**
         * 新增生产厂家验证
         */
        onDeviceFactureCheck(){
            if(this.manufacturerParams.manufacturerName === ''){
                Message.warning('请输入生产厂家！')
                return
            }
            if(this.deviceFactureSelect.some((item)=>{ return item.name === this.manufacturerParams.manufacturerName })){
                Message.warning('信息重复，请重新输入！')
                return
            }
            // if(this.deviceParams.manufacturer.length === 0){
            //     Message.error('请选择生产厂家！')
            //     return
            // }
            this.onAddManufacturer().then()
        },
        /**
         * 新增生产厂家验证
         */
        onManufacturerCheck(){
            if(this.manufacturerParams.manufacturerName === ''){
                Message.error('请输入您要添加的生产厂家！')
                return
            }
            this.onAddManufacturer().then()
        },
        /**
         * 清空
         * @param type
         */
        onClearImage(type) {
            if(type === 6){
                this.$refs.pathClear.value = ''
                this.newlyParams.files = ''
                this.newlyParams.filesUrl = ''
            }
            if(type === 4){
                this.$refs.pathClear.value = ''
                this.structuralParams.fileUrl = ''
                this.structuralParams.resourceName = ''
            }
        },
        /**
         * 清空处理方法资源文件
         * @param key
         */
        onClearReasonImage(key) {
            this.reasonArray.solutionList[key].solutionResourcesFileUrl = ''
            this.reasonArray.solutionList[key].picUrl = ''
            this.$forceUpdate()
        },
        /**
         * 获取点坐标
         * @param e
         * @returns {{x: number, y: number}}
         */
        getPoint(e) {
            if (e.touches && e.touches.length > 0) {
                let touch = e.touches[0];
                return { x:touch.pageX, y: touch.pageY};
            }
            return { x: e.pageX, y: e.pageY};
        },
        /**
         * 鼠标滚轮事件
         * @param e
         */
        onMousewheel(e) {
            if(e.wheelDelta > 0){
                this.onImageEnlarge()
            }else{
                this.onImageNarrow()
            }
        },
        /**
         * 记录按下的事件
         * @param e
         * @returns {{x: number, y: number}}
         */
        onMouseDown(e) {
            this.drawing = true;
            this.currentX = e.offsetX
            this.currentY = e.offsetY
        },
        /**
         * 记录抬起的事件
         * @param e
         */
        onMouseUp() {
            if (!this.drawing) { return; }
            this.drawing = false;
        },
        /**
         * 记录移动的事件
         * @param e
         */
        onMouseMove(e) {
            if (!this.drawing) { return; }
            this.imageLeft = e.clientX - this.currentX
            this.imageTop = e.clientY - this.currentY
        },
        /**
         * 图片放大比例
         */
        onImageEnlarge() {
            if((this.imageRatio + 25 ) > 1000){
                this.imageRatio = 1000
                return
            }
            this.imageRatio+=25

            this.imageLeft -= (this.imageWidth*(this.imageRatio/100) - this.imageWidth*((this.imageRatio-25)/100))/2
            this.imageTop -= (this.imageHeight*(this.imageRatio/100) - this.imageHeight*((this.imageRatio-25)/100))/2
        },
        /**
         * 图片缩小比例
         */
        onImageNarrow() {
            if((this.imageRatio - 25 ) < 25){
                this.imageRatio = 25
                return
            }
            this.imageRatio-=25
            this.imageLeft += (this.imageWidth*(this.imageRatio/100) - this.imageWidth*((this.imageRatio-25)/100))/2
            this.imageTop += (this.imageHeight*(this.imageRatio/100) - this.imageHeight*((this.imageRatio-25)/100))/2
        },
        /**
         * 还原图片
         */
        onReduction() {
            this.imageRatio = 100
            this.imageLeft = (window.innerWidth - this.imageWidth*(this.imageRatio/100))/2
            this.imageTop = (window.innerHeight - this.imageHeight*(this.imageRatio/100))/2
        },
        /**
         * 查看大图
         * @param obj
         */
        onLookImage(obj) {
            this.onImageLoad(obj.picUrl)
            this.imageObj = obj
            this.reasonVisible = false
            this.reasonLookVisible = false
            this.reasonBigLookVisible = true
        },
        /**
         * 图片初始化
         * @param src
         */
        onImageLoad(src){
            this.imageLoading = true
            let image = new Image();
            image.src = src
            image.onload = ()=>{
                this.imageLoading = false
                if(image.height>window.innerHeight){
                    if(image.width>image.height){
                        this.imageWidth = image.height/image.width*window.innerHeight
                    }{
                        this.imageWidth = image.width/image.height*window.innerHeight
                    }
                    this.imageHeight = window.innerHeight
                }else{
                    this.imageWidth = image.width
                    this.imageHeight = image.height
                }
                this.imageLeft = (window.innerWidth- this.imageWidth)/2
                this.imageTop = (window.innerHeight- this.imageHeight)/2
            }
        },
        /**
         * 验证图片格式/拉取参数
         * @param e
         * @returns {Promise<void>}
         */
        async onReasonUpLoad(e,key) {
            let files = e.target.files[0]
            if(files){
                if(files.type !== 'image/jpeg' && files.type !== 'image/png' && files.type !== 'image/gif'){
                    Message.warning('文件格式错误，只能上传jpg/png/gif文件！')
                    return;
                }
                if(files.size > 10*1024*1024){
                    Message.warning('只能上传10M以内的图片！')
                    return;
                }
                this.reasonLoading = true
                onSysncUploadFiles([files],systemUploadType.knowledge_image).then((data)=>{
                    this.reasonArray.solutionList[key].solutionResourcesFileUrl = data[0].resourceName
                    this.reasonArray.solutionList[key].picUrl = data[0].url
                    this.complete = 0
                    this.reasonLoading = false
                    this.$forceUpdate()
                }).catch(()=>{
                    this.reasonLoading = false
                    this.complete = 0
                    Message.error('上传失败，请重新尝试！')
                })
            }
        },
        /**
         * 图片放大
         * @param name
         * @param src
         */
        onImageAmplify(name,src,fileUrl) {
            let suffixIndex = fileUrl.lastIndexOf('.')
            let suffix=fileUrl.substring(suffixIndex+1).toUpperCase();
            if(suffix!="BMP"&&suffix!="JPG"&&suffix!="JPEG"&&suffix!="PNG"&&suffix!="GIF"){
                window.open(src,'_blank')
            }else{
                this.imageName = name
                this.imageSrc = src
                this.viewImageVisible = true
            }
        },
        /**
         * 查看文件
         * @param row
         */
        onLookFile(row){
            this.imageObj.picUrl = row.file
            this.imageObj.solution = row.fileName
            let suffixIndex = row.fileName.lastIndexOf('.')
            let suffix=row.fileName.substring(suffixIndex+1).toUpperCase();
            if(suffix!="BMP"&&suffix!="JPG"&&suffix!="JPEG"&&suffix!="PNG"&&suffix!="GIF"){
                window.open(row.file,'_blank')
            }else{
                this.onLookImage(this.imageObj)
            }
        },
        /**
         * 修改文件名称
         */
        onDeviceRename(key){
            for(let i in this.deviceInfoArray){
                this.deviceInfoArray[i].visible = false
            }
            this.deviceRename = this.deviceInfoArray[key].fileName
            this.deviceInfoArray[key].visible? this.deviceInfoArray[key].visible = false : this.deviceInfoArray[key].visible = true
            this.$nextTick(()=>{
                this.$refs.editor.focus()
            })
            this.$forceUpdate()
        },
        /**
         * 重命名验证
         * @param row
         * @param key
         */
        onBlurRename(row,key) {
            this.$forceUpdate()
            if(this.deviceRename === this.deviceInfoArray[key].fileName || this.deviceRename === ''){
                for(let i in this.deviceInfoArray){
                    this.deviceInfoArray[i].visible = false
                }
                return
            }
            this.onElectricalAtlasRenameService(row,key).then(()=>{
                for(let i in this.deviceInfoArray){
                    this.deviceInfoArray[i].visible = false
                }
            })
        },
        /**
         * 型号重置
         */
        onDeviceModalResetting() {
            this.deviceModelValue = ''
            this.deviceModelSelect = this.deviceModelArray
            for(let i in this.deviceModelSelect){
                this.deviceModelSelect[i].visible = true
            }
            this.deviceBtnVisible = false
            this.$forceUpdate()
        },
        /**
         * 厂家重置
         */
        onDeviceFactureResetting() {
            this.deviceFactureValue = ''
            this.deviceFactureSelect = this.manufacturerArray
            for(let i in this.deviceFactureSelect){
                this.deviceFactureSelect[i].visible = true
            }
            this.deviceBtnVisible = false
            this.$forceUpdate()
        },
        onDeviceModalEdit(key){
            for(let i in this.deviceModelSelect){
                this.deviceModelSelect[i].visible = true
            }
            this.deviceModelInfo = this.deviceModelSelect[key].name
            this.deviceModelSelect[key].visible? this.deviceModelSelect[key].visible = false : this.deviceModelSelect[key].visible = true
            this.deviceAddVisible = false
            this.deviceParams.deviceName = ''
            this.$forceUpdate()
        },
        onDeviceFactureEdit(key){
            for(let i in this.deviceFactureSelect){
                this.deviceFactureSelect[i].visible = true
            }
            this.deviceFactureInfo = this.deviceFactureSelect[key].name
            this.deviceFactureSelect[key].visible? this.deviceFactureSelect[key].visible = false : this.deviceFactureSelect[key].visible = true
            this.deviceAddVisible = false
            this.manufacturerParams.manufacturerName= ''
            this.$forceUpdate()
        },
        onDeviceModalClose(key){
            this.deviceModelSelect[key].visible? this.deviceModelSelect[key].visible = false : this.deviceModelSelect[key].visible = true
            this.deviceModelInfo = ''
            this.$forceUpdate()
        },
        onDeviceFactureClose(key){
            this.deviceFactureSelect[key].visible? this.deviceFactureSelect[key].visible = false : this.deviceFactureSelect[key].visible = true
            this.deviceFactureInfo = ''
            this.$forceUpdate()
        },
        onDeviceModelEditCheck(key) {
            if(this.deviceModelInfo === ''){
                Message.warning('请输入设备型号')
                return
            }
            if(this.deviceModelSelect.some((item)=>{ return item.name === this.deviceModelInfo})){
                Message.warning('信息重复，请重新输入！')
                return
            }
            this.onDeviceModelEdit(this.deviceModelSelect[key].id).then()
        },
        onDeviceFactureEditCheck(key) {
            if(this.deviceFactureInfo === ''){
                Message.warning('请输入生产厂家')
                return
            }
            if(this.deviceFactureSelect.some((item)=>{ return item.name === this.deviceFactureInfo})){
                Message.warning('信息重复，请重新输入！')
                return
            }
            this.onDeviceFactureEditService(this.deviceFactureSelect[key].id).then()
        },
        /**
         * 删除设备型号
         * @param key
         */
        onDeviceModalDel(key) {
            this.onDeviceModelDelete(this.deviceModelSelect[key].id).then()
        },
        /**
         * 删除设备厂家
         * @param key
         */
        onDeviceFactureDel(key) {
            this.onDeviceFactureDelete(this.deviceFactureSelect[key].id).then()
        },
        /**
         * 搜索型号
         */
        onDeviceModalSearch() {
            if(this.deviceModelValue === '') return
            this.deviceModelSelect = this.onFilter(this.deviceModelArray,this.deviceModelValue)
            this.deviceBtnVisible = true
            this.deviceAddVisible = false
            this.deviceParams.deviceName = ''
        },
        /**
         * 搜索厂家
         */
        onDeviceFactureSearch() {
            if(this.deviceFactureValue === '') return
            this.deviceFactureSelect = this.onFilter(this.manufacturerArray,this.deviceFactureValue)
            this.deviceBtnVisible = true
            this.deviceAddVisible = false
            this.manufacturerParams.manufacturerName= ''
        },
        /**
         * 点击型号弹框关闭状态
         * @param status
         */
        onModelVisibleChange(status){
            if(!status){
                this.onDeviceModalResetting()
                this.deviceParams.deviceName = ''
                this.deviceAddVisible = false
            }
        },
        /**
         * 点击厂家弹框关闭状态
         * @param status
         */
        onFactureVisibleChange(status){
            if(!status){
                this.onDeviceFactureResetting()
                this.manufacturerParams.manufacturerName= ''
                this.deviceAddVisible = false
            }
        },
        /**
         * 新增型号
         */
        onDeviceAddModel() {
            this.deviceAddVisible = true
            for(let i in this.deviceModelSelect){
                this.deviceModelSelect[i].visible = true
            }
            this.deviceModelInfo = ''
            this.$nextTick(()=>{
                let deviceList = document.getElementById('deviceList')
                deviceList.scrollTop = (this.deviceModelArray.length+1)*42-273
            })
        },
        /**
         * 新增厂家
         */
        onDeviceAddFacture() {
            this.deviceAddVisible = true
            for(let i in this.deviceFactureSelect){
                this.deviceFactureSelect[i].visible = true
            }
            this.deviceFactureInfo = ''
            this.$nextTick(()=>{
                let deviceList = document.getElementById('deviceFactureList')
                deviceList.scrollTop = (this.manufacturerArray.length+1)*42-273
            })
        },
        /**
         * 过滤列表相同名称
         * @param array
         * @param content
         * @returns {*[]}
         */
        onFilter(array,content){
            return array.filter((value)=>{  //过滤数组元素
                return value.name.includes(content); //如果包含字符返回true
            });
        },
        /**
         * 替换属性名
         * @param data
         */
        onReplaceAttribute(data){
            let item = []
            data.map(list => {
                let newData = {}
                newData.value = list.id;
                newData.label = list.name;
                newData.children = list.childNode;
                if(list.childNode){
                    newData.children = this.onReplaceAttribute(list.childNode)
                }
                item.push(newData)
            })
            return item
        },
        /**
         * 结构部件上传文件
         * @param event
         * @returns {Promise<void>}
         */
        async onStructuralFileChange(event){
            const file =  event.target.files[0];
            this.fileType = file.type
            console.log(file.type)
            if (!['image/png', 'image/jpg', 'image/gif', 'image/jpeg','video/mp4'].includes(file.type)) {
                Message.warning('请上传正确的文件')
                return
            }
            if (file.size > 20 * 1024 * 1024) {
                Message.warning('请选择20M以内的文件')
                return
            }
            this.speedSingleVisible = true
            onSysncUploadFiles([file],systemUploadType.knowledge_image).then((data)=>{
                this.speedSingleVisible = false
                this.structuralParams.fileUrl = data[0].url
                this.structuralParams.resourceName = data[0].resourceName
                this.complete = 0
            }).catch(()=>{
                this.speedSingleVisible = false
                this.complete = 0
                Message.error('上传失败，请重新尝试！')
            })
        },
        /**
         * 上传工作原理图
         */
        choiceVideo(key) {
            this.$refs.principleVideo.dispatchEvent(new MouseEvent('click'))
            this.principleKey = key
        },
        /**
         * 删除工作原理
         * @param key
         */
        onPrincipleFileDelete(key){
            this.deviceInfoObj.detailDTOList.splice(key,1)
        },
        choicePdfElectrical() {
            this.$refs.pdf.dispatchEvent(new MouseEvent('click'))
        },
        onParamTitle(key){
            if(this.parameterStatus){
                this.deviceInfoArray[key].visible = true
                this.$nextTick(()=>{
                    this.$refs.paramTitle[0].focus()
                })
            }
        },
        onParameterName(key,index){
            if(this.parameterStatus){
                for(let i in this.deviceInfoArray[key].paramDetail){
                    this.deviceInfoArray[key].paramDetail[i].nameVisible = false
                    this.deviceInfoArray[key].paramDetail[i].paramVisible = false
                }
                this.deviceInfoArray[key].paramDetail[index].nameVisible = true
                this.$nextTick(()=>{
                    this.$refs.refInput[0].focus()
                })
            }
        },
        onParameterParam(key,index){
            if(this.parameterStatus){
                for(let i in this.deviceInfoArray[key].paramDetail){
                    this.deviceInfoArray[key].paramDetail[i].nameVisible = false
                    this.deviceInfoArray[key].paramDetail[i].paramVisible = false
                }
                this.deviceInfoArray[key].paramDetail[index].paramVisible = true
                this.$nextTick(()=>{
                    this.$refs.refInput[0].focus()
                })
            }
        },
        onParameterSave(){
            let resultRegular = false
            for(let i in this.deviceInfoArray){
                if(this.deviceInfoArray[i].titleName === '' || this.deviceInfoArray[i].titleName === '未命名'){
                    resultRegular = true
                }
                for(let j in this.deviceInfoArray[i].paramDetail){
                    if(this.deviceInfoArray[i].paramDetail[j].paramName === '' || this.deviceInfoArray[i].paramDetail[j].paramValue === ''){
                        resultRegular = true
                    }
                }
            }
            if(resultRegular){
                Message.warning('您有未填项目，请添加后保存！')
            }else{
                this.onUpdateDeviceInfoParam().then()
            }
        },
        /**
         * 添加技术参数
         * @param key
         */
        onParameterAdd(key){
            let resultRegular = this.deviceInfoArray[key].paramDetail.some(item =>{
                if(item.paramName === '' || item.paramValue === ''){
                    return true
                }
            })
            if(resultRegular){
                Message.warning('当前栏目有未填项目！')
            }else{
                this.deviceInfoArray[key].paramDetail.push({
                    paramName:'',
                    paramValue:'',
                    nameVisible:false,
                    paramVisible:false
                })
                this.$forceUpdate()
            }
        },
        onAddParameter(){
            let resultRegular = false
            for(let i in this.deviceInfoArray){
                if(this.deviceInfoArray[i].titleName === '' || this.deviceInfoArray[i].titleName === '未命名'){
                    resultRegular = true
                }
                for(let j in this.deviceInfoArray[i].paramDetail){
                    if(this.deviceInfoArray[i].paramDetail[j].paramName === '' || this.deviceInfoArray[i].paramDetail[j].paramValue === ''){
                        resultRegular = true
                    }
                }
            }
            if(resultRegular){
                Message.warning('您有未填项目，请填写后新增！')
            }else{
                this.deviceInfoArray.push({
                    equipmentId:'',
                    partsId:'',
                    titleName:'未命名',
                    visible:false,
                    paramDetail:[{
                        paramName:'',
                        paramValue:'',
                        nameVisible:false,
                        paramVisible:false
                    }]
                })
            }
        },
        onDeleteParamItem(key){
            Modal.confirm({
                title: '删除当前条目',
                content: '<p>您确定要删除这条信息吗？</p>',
                onOk: () => {
                    this.deviceInfoArray.splice(key,1)
                }
            });
        },
        onParamToppingItem(key){
            this.deviceInfoArray.unshift(this.deviceInfoArray.splice(key,1)[0])
            for(let i in this.deviceInfoArray){
                for(let j in this.deviceInfoArray[i].paramDetail){
                    this.deviceInfoArray[i].paramDetail[j].nameVisible = false
                    this.deviceInfoArray[i].paramDetail[j].paramVisible = false
                }
            }
        },
        onParamUpItem(key){
            this.deviceInfoArray.splice(key-1,1,...  this.deviceInfoArray.splice(key,1, this.deviceInfoArray[key-1]))
            for(let i in this.deviceInfoArray){
                for(let j in this.deviceInfoArray[i].paramDetail){
                    this.deviceInfoArray[i].paramDetail[j].nameVisible = false
                    this.deviceInfoArray[i].paramDetail[j].paramVisible = false
                }
            }
        },
        onParamDownItem(key){
            this.deviceInfoArray.splice(key,1,...this.deviceInfoArray.splice(key+1,1, this.deviceInfoArray[key]))
            for(let i in this.deviceInfoArray){
                for(let j in this.deviceInfoArray[i].paramDetail){
                    this.deviceInfoArray[i].paramDetail[j].nameVisible = false
                    this.deviceInfoArray[i].paramDetail[j].paramVisible = false
                }
            }
        },
        /**
         * 删除技术参数内部当前条目
         * @param key
         * @param index
         */
        onDeleteParam(key,index){
            Modal.confirm({
                title: '删除当前条目',
                content: '<p>您确定要删除这条信息吗？</p>',
                onOk: () => {
                    this.deviceInfoArray[key].paramDetail.splice(index,1)
                }
            });
        },
        /**
         * 技术参数置顶
         * @param key
         * @param index
         */
        onParamTopping(key,index){
            this.deviceInfoArray[key].paramDetail.unshift(this.deviceInfoArray[key].paramDetail.splice(index,1)[0])
            for(let i in this.deviceInfoArray){
                for(let j in this.deviceInfoArray[i].paramDetail){
                    this.deviceInfoArray[i].paramDetail[j].nameVisible = false
                    this.deviceInfoArray[i].paramDetail[j].paramVisible = false
                }
            }
        },
        /**
         * 技术参数上移
         * @param key
         * @param index
         */
        onParamUp(key,index){
            this.deviceInfoArray[key].paramDetail.splice(index-1,1,...  this.deviceInfoArray[key].paramDetail.splice(index,1, this.deviceInfoArray[key].paramDetail[index-1]))
            for(let i in this.deviceInfoArray){
                for(let j in this.deviceInfoArray[i].paramDetail){
                    this.deviceInfoArray[i].paramDetail[j].nameVisible = false
                    this.deviceInfoArray[i].paramDetail[j].paramVisible = false
                }
            }
        },
        /**
         * 技术参数下移
         * @param key
         * @param index
         */
        onParamDown(key,index){
            this.deviceInfoArray[key].paramDetail.splice(index,1,...this.deviceInfoArray[key].paramDetail.splice(index+1,1, this.deviceInfoArray[key].paramDetail[index]))
            for(let i in this.deviceInfoArray){
                for(let j in this.deviceInfoArray[i].paramDetail){
                    this.deviceInfoArray[i].paramDetail[j].nameVisible = false
                    this.deviceInfoArray[i].paramDetail[j].paramVisible = false
                }
            }
        },
        /**
         * 上移
         * @param key
         */
        onProgressUp(key){
            this.deviceInfoObj.detailDTOList.splice(key-1,1,...this.deviceInfoObj.detailDTOList.splice(key,1, this.deviceInfoObj.detailDTOList[key-1]))
        },
        /**
         * 下移
         * @param key
         */
        onProgressDown(key){
            this.deviceInfoObj.detailDTOList.splice(key,1,...this.deviceInfoObj.detailDTOList.splice(key+1,1, this.deviceInfoObj.detailDTOList[key]))
        },
        /**
         * 置顶
         * @param key
         */
        onProgressTopping(key){
            this.deviceInfoObj.detailDTOList.unshift(this.deviceInfoObj.detailDTOList.splice(key,1)[0])
        },
        /**
         * pdf转换验证
         */
        onConvert(){
            if(this.pdfPageEnd < this.pdfPageStar ){
                Message.warning('转换的页码起始值不可大于结束值！')
                return
            }
            this.onConvertPdfElectricalService().then()
        },
        /**
         * 工作原理上传文件
         * @param event
         */
        onPrincipleFileChange(event){
            this.complete = 0;
            const file =  event.target.files[0];
            this.fileType = file.type
            this.$refs.principleVideo.value = ''
            if (!['video/mp4','video/flv','image/jpg','image/jpeg','image/png'].includes(file.type)) {
                Message.warning('请上传正确的视频格式')
                return
            }
            if (file.size > 100 * 1024 * 1024) {
                Message.warning('请选择100M以内的图片')
                return
            }
            let imageFormat
            if(file.type!="image/jpeg"&&file.type!="image/jpg"&&file.type!="image/png"){
                imageFormat = 'video'
            }else{
                imageFormat = 'image'
            }
            this.devicePrincipleLoading = true
            if(JSON.stringify(this.deviceInfoObj)!=='{}' && this.deviceInfoObj.detailDTOList.length !== 0){
                if(this.principleKey === this.deviceInfoObj.detailDTOList.length){
                    this.deviceInfoObj.detailDTOList.push({
                        file:'',
                        fileUrl:'',
                        visible:true,
                        title:'',
                        fileFormat:imageFormat,
                    })
                }else{
                    this.deviceInfoObj.detailDTOList[this.principleKey].visible = true
                }
            }else{
                this.deviceInfoObj.detailDTOList = [{
                    file:'',
                    fileUrl:'',
                    visible:true,
                    title:'',
                    fileFormat:imageFormat,
                }]
            }
            onSysncUploadFiles([file],systemUploadType.knowledge_image).then((data)=>{
                this.devicePrincipleLoading = false
                this.deviceInfoObj.detailDTOList[this.principleKey].fileFormat = imageFormat
                this.deviceInfoObj.detailDTOList[this.principleKey].file = data[0].url
                this.deviceInfoObj.detailDTOList[this.principleKey].fileUrl = data[0].resourceName
                this.deviceInfoObj.detailDTOList[this.principleKey].visible = false
                this.complete = 100;
            }).catch(()=>{
                this.devicePrincipleLoading = false
                this.deviceInfoObj.detailDTOList[this.principleKey].visible = false
                this.complete = 0
                Message.error('上传失败，请重新尝试！')
            })
        },
        /**
         * 上传文件
         * @param event
         */
         async onFileChange(event) {
             const file =  event.target.files[0];
             this.$refs.pathClear.value = ''
             this.fileType = file.type
             if (!['image/png', 'image/jpg', 'image/gif', 'image/jpeg'].includes(file.type)) {
                 Message.warning('请上传正确的图片')
                 return
             }
             if (file.size > 20 * 1024 * 1024) {
                 Message.warning('请选择20M以内的图片')
                 return
             }

            this.speedSingleVisible = true
            compressImage(file,200).then((obj)=>{
                onSysncUploadFiles([obj],systemUploadType.knowledge_image).then((data)=>{
                    this.speedSingleVisible = false
                    this.newlyParams.filesUrl = data[0].url
                    this.newlyParams.files = data[0].resourceName
                    this.newlyParams.fileName = data[0].fileName
                    this.complete = 0
                }).catch(()=>{
                    this.speedSingleVisible = false
                    this.complete = 0
                    this.onClearImage(6)
                    Message.error('上传失败，请重新尝试！')
                })
            })

             // if (typeof FileReader === 'function') {
             //     const reader = new FileReader();
             //     reader.readAsDataURL(file);
             //     reader.onload = (event) => {
             //         let Base64Val = event.target.result;
             //         this.imageSrc = Base64Val
             //    }
             // } else {
             //     Message.warning('对不起，您的浏览器暂不支持此功能！');
             // }

        },
        /**
         * 解析pdf文件
         * @param event
         * @param n
         */
        onPdfFileChange(event,n) {
            const file =  event.target.files[0];
            this.$refs.pdf.value = ''
            if(file.size > 30 * 1024 * 1024){
                Message.warning(file.name+'文件不可超过30M!')
                return
            }
            this.pdfAddVisible = false
            this.pdfName = file.name
            this.pdfModalVisible = true
            this.pdfUploadStatus = true
            this.completePdf = 0
            onSysncUploadFiles([file],systemUploadType.knowledge_image).then((data)=>{
                this.onAddPdfElectricalService(data[0].resourceName,n).then(()=>{
                    setTimeout(()=>{
                        this.onPdfElectricalProgressService(data[0].resourceName).then()
                    })
                }).catch(()=>{
                    this.pdfUploadStatus = false
                })
            }).catch(()=>{
                this.completePdf = 0
                this.pdfUploadStatus = false
                Message.error('上传失败，请重新尝试！')
            })
        },
        /**
         * 上传资料文件
         * @param event
         * @param n
         */
        onFileDataChange(event,n){
            const file =  event.target.files;
            // if (file.size > 100 * 1024 * 1024) {
            //     Message.error('请选择100M以内的文件!')
            //     return
            // }
            if(n === 1){
                const file =  event.target.files[0];
                if(file.type !=='application/pdf'){
                    Message.warning('只能上传PDF文件！')
                    this.$refs[`dataInputer${n}`].value = ''
                    return
                }
            }
            for(let i in file){
                // if(n === 2 && ['image/png', 'image/jpg', 'image/gif', 'image/jpeg'].includes(file[i].type)){
                //     Message.warning('请上传正确的图片')
                //     return
                // }
                if(file[i].size > 20 * 1024 * 1024){
                    Message.warning(file[i].name+'文件不可超过20M!')
                    this.$refs[`dataInputer${n}`].value = ''
                    return
                }
                if(file[i].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file[i].type === 'application/msword'){
                    Message.warning('不可上传.doc、.docx文件！')
                    this.$refs[`dataInputer${n}`].value = ''
                    return
                }
            }
            this.speedList = []
            this.speedList = file
            this.speedVisible = true
            onSysncUploadFiles(file,systemUploadType.knowledge_image).then((data)=>{
                let fileName = []
                for(let i in data){
                    fileName.push(data[i].resourceName)
                }
                this.onAddDeviceData(fileName,n).then(()=>{
                    this.$refs[`dataInputer${n}`].value = ''
                })
            }).catch(()=>{
                this.speedVisible = false
                this.complete = 0
                this.$refs[`dataInputer${n}`].value = ''
                Message.error('上传失败，请重新尝试！')
            })
        },
        /**
         * 重新上传资料文件
         * @param event
         * @param n
         */
        onUpdateFileDataChange(event,id,type){
            const file =  event.target.files[0];
            if(file){
                if (file.size > 20 * 1024 * 1024) {
                    Message.error('请选择20M以内的文件')
                    return
                }
                this.speedList = []
                this.speedList = [file]
                this.speedVisible = true
                onSysncUploadFiles([file],systemUploadType.knowledge_image).then((data)=>{
                    this.speedVisible = false
                    this.onUpdateDeviceData(id,data[0].resourceName).then(()=>{
                        this.$refs[`filePath${type}`].value = ''
                    })
                    this.complete = 0
                }).catch(()=>{
                    this.$refs[`filePath${type}`].value = ''
                    this.speedVisible = false
                    this.complete = 0
                    Message.error('上传失败，请重新尝试！')
                })
            }
        },
        /**
         * 故障分析移动排序
         * @param id
         * @param type
         */
        onSortableFault(id,type){
            this.failureAnalysisFaultId = ''
            this.onFailureMoveService(id,type).then()
        },
        /**
         * 批量删除资料文件
         * @param type
         */
        onBatchDelete(){
            if(this.deviceListParams.checkInput.length === 0){
                Message.warning('请选择要删除的文件')
                return
            }
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除这些文件吗？</p>',
                onOk: () => {
                    this.onDelDeviceData(this.deviceListParams.checkInput).then()
                }
            });
        },
        /**
         * 批量删除电器图册文件
         * @param type
         */
        onBatchAtlasDelete(){
            if(this.deviceListParams.checkInput.length === 0){
                Message.warning('请选择要删除的文件')
                return
            }
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除这些文件吗？</p>',
                onOk: () => {
                    this.onDelDeviceAtlasService(this.deviceListParams.checkInput).then()
                }
            });
        },
        /**
         * 下载故障分析模版数据
         */
        onFailureDownLoad() {
            this.onFailureDownLoadService(this.deviceInfo.id).then()
        },
        /**
         * 导出故障分析数据
         */
        onFailureExport() {
            this.onFailureExportService(this.deviceInfo.id).then()
        },
        /**
         * 批量删除故障分析文件
         * @param type
         */
        onFailureBatchDelete(){
            if(this.deviceListParams.checkInput.length === 0){
                Message.warning('请选择要删除的文件')
                return
            }
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除这些文件吗？</p>',
                onOk: () => {
                    this.onDelFailureData(this.deviceListParams.checkInput).then()
                }
            });
        },
        /**
         * 批量删除结构部件文件
         * @param type
         */
        onStructuralBatchDelete(){
            if(this.deviceListParams.checkInput.length === 0){
                Message.warning('请选择要删除的文件')
                return
            }
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除这些文件吗？</p>',
                onOk: () => {
                    this.onDelStructuralService(this.deviceListParams.checkInput).then()
                }
            });
        },
        /**
         * 删除资料文件
         * @param id
         */
        onDeleteFileData(id){
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除这条资料吗？</p>',
                onOk: () => {
                    this.onDelDeviceData([id]).then()
                }
            });
        },
        /**
         * 删除故障分析
         * @param id
         */
        onDelFailure(id){
            Modal.confirm({
                title: '删除故障分析',
                content: '<p>您确定要删除这条信息吗？</p>',
                onOk: () => {
                    this.onDelFailureData([id]).then()
                }
            });
        },
        /**
         * 删除结构部件
         * @param id
         */
        onDelStructural(id){
            Modal.confirm({
                title: '删除结构部件',
                content: '<p>您确定要删除这条信息吗？</p>',
                onOk: () => {
                    this.onDelStructuralService([id]).then()
                }
            });
        },
        /**
         * 删除故障分析
         * @param id
         */
        onDelReason(id){
            Modal.confirm({
                title: '删除可能原因',
                content: '<p>您确定要删除这条信息吗？</p>',
                onOk: () => {
                    this.onDelReasonData(id).then()
                }
            });
        },
        /**
         * 新增数据验证
         */
        onAddRecordCheck() {
            if(this.newlyParams.deviceType.length === 0){
                Message.error('请选择设备分类！')
                return;
            }
            if(this.newlyParams.deviceName === ''){
                Message.error('请输入设备名称！')
                return;
            }
            if(this.newlyParams.deviceModel === ''){
                Message.error('请选择设备型号！')
                return;
            }
            if(this.newlyParams.manufacturer === ''){
                Message.error('请选择生产厂家！')
                return;
            }
            this.onUpdateDeviceInfo().then()
        },
        /**
         * 新增设备简介验证
         */
        onAddDeviceContentCheck() {
            this.onUpdateDeviceInfoIntro().then()
        },
        /**
         * 新增工作原理验证
         */
        onAddDevicePrincipleCheck() {
            let principleStatus = this.deviceInfoObj.detailDTOList.some(item =>{
                if(item.title === ''){
                    return true
                }
            })
            if(principleStatus){
                Message.warning('您有未添加的标题！')
                return
            }
            this.onUpdateDeviceInfoPrinciple().then()
        },
        /**
         * 取消
         */
        onCancel() {
            this.$router.push('/device/list')
        },
        /**
         * 添加故障描述
         */
        onAddFailureCheck() {
            if(this.faultParams.describe === ''){
                Message.error('请输入故障描述！');
                return;
            }
            // if(this.faultParams.code === ''){
            //     Message.error('请输入故障代码！');
            //     return;
            // }
            this.faultStatus?this.onAddFailure().then():  this.onEditFailureService().then()
        },
        /**
         * 编辑结构部件验证
         */
        onAddStructuralCheck() {
            if(this.structuralParams.partsName === ''){
                Message.error('请输入部件名称！');
                return;
            }
            this.structuralStatus?this.onAddStructural().then():  this.onEditStructuralService().then()
        },
        /**
         * 编辑故障分析
         * @param row
         */
        onEditFailure(row){
            this.addFaultVisible = true
            if(row === ''){  //新增
                this.faultStatus = true
                this.faultParams.describe = ''
                this.faultParams.code = ''
                this.faultParams.id = ''
            }else{  //编辑
                this.faultStatus = false
                this.faultParams.id = row.id
                this.faultParams.describe = row.describe
                this.faultParams.code = row.code
            }
        },
        /**
         * 升级结构部件
         * @param id
         */
        onUpgradeDevice(id){
            this.onUpgradeStructuralService(id).then()
        },
        /**
         * 编辑结构部件
         * @param row
         */
        onEditStructural(row,parentId,array,type){
            this.structuralVisible = true
            console.log(array)
            if(row === ''){  //新增
                this.structuralStatus = true
                this.structuralParams.id = ''
                this.structuralParams.partsName = ''
                this.structuralParams.fileUrl = ''
                this.structuralParams.resourceName = ''
                this.structuralParams.partsBlurb = ''
                this.structuralParams.parentId = parentId
                this.structuralParams.partsModel = ''
            }else{   //编辑
                this.structuralStatus = false
                this.structuralParams.id = row.id
                this.structuralParams.partsName = row.partsName
                this.structuralParams.fileUrl = row.file
                this.structuralParams.resourceName = row.fileUrl
                this.structuralParams.partsBlurb = row.partsBlurb
                this.structuralParams.parentId = parentId
                this.devicePartTypeList = array
                this.devicePartType = type
                this.structuralParams.partsModel = row.partsModel
            }
        },
        /**
         * 选中结构部件
         * @param item
         */
        onSelectPart(item) {
            this.SET_STRUCTURAL_ID(item.id)
            this.$nextTick(()=>{
                this.deviceSelectPart = item
                this.onStructuralInfoIdService().then()
                this.onDeviceInfo().then()
            })
            this.$forceUpdate()
        },
        onDevice(){
            this.SET_STRUCTURAL_ID('')
            this.onDeviceInfo().then()
        },
        /**
         * 新增处理方法
         */
        onAddProcessing() {
            if(this.reasonArray.solutionList.some(val => val.solution === '')){
                Message.warning('您有处理方法的说明未添加！');
                return;
            }
            if(this.reasonArray.solutionList.length>=20){
                Message.warning('处理方法最多添加20条！');
                return;
            }
            this.reasonArray.solutionList.push({
                solution:'',
                solutionResourcesFileUrl:'',
            })
        },
        /**
         * 删除处理方法
         * @param key
         */
        onRemoveProcessing(key) {
            this.reasonArray.solutionList.splice(key,1)
        },
        /**
         * 排序处理方法
         * @param array
         * @param key
         * @param type
         */
        onReasonSort(array,key,type){
            if(type === 'up'){
                swapArray(array, key, key - 1);
            }
            if(type === 'down'){
                swapArray(array, key, key + 1);
            }
        },
        /**
         * 添加可能原因
         */
        onAddReasonCheck() {
            if(this.reasonArray.reason === ''){
                Message.warning('请输入原因！');
                return;
            }
            if(this.reasonArray.solutionList.some(val => val.solution === '')){
                Message.warning('您有处理方法的说明未添加！');
                return;
            }
            this.reasonStatus?this.onAddReason().then():this.onEditReason().then()

        },
        /**
         * 跳转编辑
         */
        onReasonEdit() {
            this.reasonVisible = true
            this.reasonStatus = false
            this.reasonLookVisible = false
            this.reasonBigLookVisible = false
        },
        /**
         * 查看图册
         * @param row
         * @param obj
         */
        onImageSwitch(row,obj,key){
            this.onReduction()
            this.imageBigArray = obj
            this.imageBigActive = key
            this.imageBigLookVisible = true
            this.imageObj.picUrl = row.file
            this.imageObj.solution = row.fileName
            this.onImageLoad(row.file)
            this.onImageActive(key)
        },
        /**
         * 列表左移动
         */
        onImageLeft() {
            if(this.imageBigNumber === 0){
                return
            }
            this.$nextTick(()=>{
                this.offsetWidth = Math.ceil(document.getElementById('carouselId').offsetWidth)
                if(this.imageBigNumber - Math.floor(this.offsetWidth/106)<0){
                    this.imageBigNumber = 0
                }else{
                    this.imageBigNumber -= Math.floor(this.offsetWidth/106)
                }
            })
        },
        /**
         * 列表右移动
         */
        onImageRight() {
            this.$nextTick(()=>{
                this.offsetWidth = Math.ceil(document.getElementById('carouselId').offsetWidth)
                if(this.imageBigNumber + Math.floor(this.offsetWidth/106)>Math.floor((this.imageBigArray.length*106-this.offsetWidth)/106)){
                    this.imageBigNumber = Math.ceil((this.imageBigArray.length*106 - this.offsetWidth)/106)
                }else{
                    this.imageBigNumber += Math.floor(this.offsetWidth/106)
                }
            })
        },
        /**
         * 大图向左切换
         */
        onImageBigLeft(){
            if(this.imageBigActive === 0){
                this.imageBigActive = this.imageBigArray.length - 1
            }else{
                this.imageBigActive-=1
            }
            this.onReduction()
            this.imageObj.picUrl = this.imageBigArray[this.imageBigActive].file
            this.imageObj.solution = this.imageBigArray[this.imageBigActive].fileName
            this.onImageLoad(this.imageObj.picUrl)
            this.onImageActive(this.imageBigActive)
        },
        /**
         * 大图向右切换
         */
        onImageBigRight() {
            if(this.imageBigActive+1>=this.imageBigArray.length){
                this.imageBigActive = 0
            }else{
                this.imageBigActive+=1
            }
            this.onReduction()
            this.imageObj.picUrl = this.imageBigArray[this.imageBigActive].file
            this.imageObj.solution = this.imageBigArray[this.imageBigActive].fileName
            this.onImageLoad(this.imageObj.picUrl)
            this.onImageActive(this.imageBigActive)
        },
        /**
         * 计算列表图片位置
         * @param key
         */
        onImageActive(key) {
            this.$nextTick(()=>{
                this.offsetWidth = Math.ceil(document.getElementById('carouselId').offsetWidth)
                if(key+1>Math.ceil(this.offsetWidth/106/2)){  //让选中的图片每次保持在页面中间位置 计算方式（宽/2）向上取整
                    if((key+1)-Math.ceil(this.offsetWidth/106/2) < this.imageBigArray.length-Math.floor(this.offsetWidth/106)){
                        this.imageBigNumber = (key+1)-Math.ceil(this.offsetWidth/106/2)
                    }else{
                        this.imageBigNumber = this.imageBigArray.length-Math.floor(this.offsetWidth/106)
                    }
                }else{
                    this.imageBigNumber = 0
                }
            })
        },
        /**
         * 编辑电气图册
         * @param id
         */
        onElectricalEdit(id){
            this.SET_ELECTRICAL_ID(id)
            this.$router.push('/device/electrical/config')
        },
        /**
         * 修改名称状态
         * @param key
         */
        onUpdateNameStatus(key,obj) {
            this.deviceInfoArray[key].nameStatus = true
            this.deviceElectricalName = obj.fileName
            this.$nextTick(()=>{
                this.$refs['electricalName'][0].focus();
            })
        },
        /**
         * 下载图片
         * @param obj
         */
        onDownLoadImage(obj) {
            saveImage(obj.file,obj.fileName)
        },
        /**
         * 修改名称
         * @param key
         */
        onUpdateName(id,key){
            this.deviceInfoArray[key].nameStatus = false
            if(this.deviceElectricalName === this.deviceInfoArray[key].fileName){
                return
            }
            if(this.deviceInfoArray[key].fileName === ''){
                Message.warning('请输入名称！')
                this.deviceInfoArray[key].nameStatus = true
                this.$nextTick(()=>{
                    this.$refs.editor.focus()
                })
                return
            }
            if(this.deviceRename === this.deviceInfoArray[key].fileName || this.deviceRename === ''){
                for(let i in this.deviceInfoArray){
                    this.deviceInfoArray[i].visible = false
                }
                return
            }
            let result = false
            for(let i in this.deviceInfoArray){
                if(this.deviceInfoArray[i].fileName === this.deviceRename){
                    result = true
                }
            }
            if(result){
                Message.warning('当前图册名称不能与其他图册名称相同！')
                this.$nextTick(()=>{
                    this.$refs.editor.focus()
                })
                return
            }
            this.onUpdateElectricListName(id,key).then(()=>{
                for(let i in this.deviceInfoArray){
                    this.deviceInfoArray[i].visible = false
                }
            })
        },
        /**
         * 发布电气图册
         */
        onElectricalRelease(id,status) {
            this.onUpdateElectricStatus(id,status).then()
        },
        /**
         * 删除电气图册验证
         * @param id
         */
        onElectricalDelete(id) {
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除这条信息吗？</p>',
                onOk: () => {
                    this.onDelElectricalList(id).then()
                }
            });
        },
        /**
         * 删除电气图册
         * @param id
         */
        async onDelElectricalList(id) {
            try {
                let params = {
                    id:id
                }
                const { code } = await delElectricalListService(params)
                if(code === 'SUCCESS'){
                    for(let i in this.deviceInfoArray){
                        if(id === this.deviceInfoArray[i].id){
                            this.deviceInfoArray.splice(i,1)
                        }
                    }
                    Message.success('删除成功！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 电气图册更新状态
         */
        async onUpdateElectricStatus(id,status) {
            try {
                let params = {
                    id:id,
                    status:status
                }
                const { code } = await updateElectricStatusService(params)
                if(code === 'SUCCESS'){
                    if(status === 1 ){
                        Message.success('发布成功！')
                    }
                    if(status === 0 ){
                        Message.success('撤回成功！')
                    }
                    for(let i in this.deviceInfoArray){
                        if(id === this.deviceInfoArray[i].id){
                            this.deviceInfoArray[i].status = status
                        }
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 修改电气图册名称
         */
        async onUpdateElectricListName(id,key) {
            try {
                let params = {
                    id:id,
                    electricAtlasName:this.deviceRename
                }
                const { code } = await updateElectricListNameService(params)
                if(code === 'SUCCESS'){
                    Message.success('修改成功！')
                    this.deviceInfoArray[key].nameStatus = false
                    this.deviceInfoArray[key].fileName = this.deviceRename
                }else if(code === 'EQUIPMENT_ELECTRIC_ATLAS_NAME_REPEAT'){
                    Message.warning('电器图册名称重复！')
                    this.$refs.editor.focus()
                }else{
                    this.deviceInfoArray[key].nameStatus = true
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取所有设备型号
         */
        async onDeviceModelAll() {
            try {
                const { code,data } = await getDeviceModelService()
                if(code === 'SUCCESS'){
                    this.deviceModelArray = data
                    this.deviceModelSelect = data
                    for(let i in this.deviceModelSelect){
                        this.deviceModelSelect[i].visible = true
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取所有生产厂家
         */
        async onManufacturerAll() {
            try {
                const { code,data } = await getManufacturerService()
                if(code === 'SUCCESS'){
                    this.manufacturerArray = data
                    this.deviceFactureSelect = data
                    for(let i in this.deviceFactureSelect){
                        this.deviceFactureSelect[i].visible = true
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 根据设备型号名称查询生产厂家
         */
        async onNameManufacturer() {
            try {
                let params = {
                    name:this.deviceParams.deviceName
                }
                const { code,data } = await getNameManufacturerService(params)
                if(code === 'SUCCESS'){
                    let list = []
                    for(let i in data){
                        list.push(data[i].id)
                    }
                    this.deviceParams.manufacturer = list
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 根据设备型号Id查询生产厂家
         */
        async onIdManufacturer() {
            try {
                this.newlyParams.manufacturer = ''
                let params = {
                    id:this.newlyParams.deviceModel
                }
                const { code,data } = await getIdManufacturerService(params)
                if(code === 'SUCCESS'){
                    this.deviceModelManufacturerArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 添加设备型号
         */
        async onDeviceModel() {
            try {
                this.submitLoading = true
                let params = {
                    modelName:this.deviceParams.deviceName,
                    // manufacturerIds:this.deviceParams.manufacturer
                }
                const { code } = await addDeviceModelService(params)
                if(code === 'SUCCESS'){
                    Message.success('添加成功')
                    this.deviceAddVisible = false
                    this.deviceParams.deviceName = ''
                    this.deviceParams.manufacturer = []
                    this.deviceModelManufacturerArray = []
                    this.onDeviceModelAll().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 编辑设备型号
         */
        async onDeviceModelEdit(id) {
            try {
                this.submitLoading = true
                let params = {
                    modelName:this.deviceModelInfo,
                    modelId:id,
                    // manufacturerIds:this.deviceParams.manufacturer
                }
                const { code } = await editDeviceModelService(params)
                if(code === 'SUCCESS'){
                    Message.success('修改成功')
                    this.deviceModelInfo = ''
                    this.onDeviceModalResetting()
                    this.onDeviceModelAll().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 删除设备型号
         */
        async onDeviceModelDelete(id) {
            try {
                this.submitLoading = true
                let params = {
                    modelId:id,
                    // manufacturerIds:this.deviceParams.manufacturer
                }
                const { code } = await deleteDeviceModelService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功')
                    this.onDeviceModelAll().then()
                }else if(code === 'EQUIPMENT_MANUFACTURER_MODEL_DELETE_FAIL_LINKED_DATA_EXISTS'){
                    Message.error('此型号已被引用，不可删除！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 添加生产厂家
         */
        async onAddManufacturer() {
            try {
                this.submitLoading = true
                let params = {
                    name:this.manufacturerParams.manufacturerName
                }
                const { code } = await addManufacturerService(params)
                if(code === 'SUCCESS'){
                    Message.success('添加成功')
                    this.deviceAddVisible = false
                    this.manufacturerParams.manufacturerName = ''
                    this.onManufacturerAll().then()
                }else if(code === 'EQUIPMENT_MANUFACTURER_REPEAT_NAME'){
                    Message.error('添加的生产厂家重复！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 编辑设备型号
         */
        async onDeviceFactureEditService(id) {
            try {
                this.submitLoading = true
                let params = {
                    name:this.deviceFactureInfo,
                    manufacturerId:id,
                    // manufacturerIds:this.deviceParams.manufacturer
                }
                const { code } = await editManufacturerService(params)
                if(code === 'SUCCESS'){
                    Message.success('修改成功')
                    this.deviceFactureInfo = ''
                    this.onDeviceFactureResetting()
                    this.onManufacturerAll().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 删除设备厂家
         */
        async onDeviceFactureDelete(id) {
            try {
                this.submitLoading = true
                let params = {
                    manufacturerId:id,
                    // manufacturerIds:this.deviceParams.manufacturer
                }
                const { code } = await deleteManufacturerService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功')
                    this.onManufacturerAll().then()
                }else if(code === 'EQUIPMENT_MANUFACTURER_DELETE_FAIL_LINKED_DATA_EXISTS'){
                    Message.error('此厂家已被引用，不可删除！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 修改设备信息
         */
        async onUpdateDeviceInfo() {
            try {
                this.submitLoading = true
                let deviceType
                if(this.newlyParams.deviceType.length === 1){
                    deviceType = this.newlyParams.deviceType[0]
                }
                if(this.newlyParams.deviceType.length === 2){
                    deviceType = this.newlyParams.deviceType[1]
                }
                if(this.newlyParams.deviceType.length === 3){
                    deviceType = this.newlyParams.deviceType[2]
                }
                let params = {
                    id:this.deviceInfo.id,
                    equipmentClassificationId:deviceType,
                    name:this.newlyParams.deviceName,
                    modelId:this.newlyParams.deviceModel,
                    manufacturerId:this.newlyParams.manufacturer,
                    picturesUrl:this.newlyParams.files
                }
                const { code } = await updateDeviceInfoService(params)
                if(code === 'SUCCESS'){
                    Message.success('修改成功')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 修改设备简介
         */
        async onUpdateDeviceInfoIntro() {
            try {
                this.deviceInfoContentLoading = true
                let params = {
                    id:this.deviceInfoObj.id,
                    equipmentId:this.deviceInfo.id,
                    introContent:this.deviceInfoContent,
                    partsId:this.structuralId
                }
                const { code } = await updateDeviceInfoIntroService(params)
                if(code === 'SUCCESS'){
                    Message.success('修改成功')
                    this.deviceInfoObj.introContent = this.deviceInfoContent
                    this.deviceInfoStatus = false
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.deviceInfoContentLoading = false
            }catch (error){
                this.deviceInfoContentLoading = false
                console.log(error)
            }
        },
        /**
         * 修改技术参数
         */
        async onUpdateDeviceInfoParam() {
            try {
                this.parameterLoading = true
                let params = {
                    equipmentId:this.deviceInfo.id,
                    partsId:this.structuralId,
                    paramList:this.deviceInfoArray
                }
                const { code } = await updateDeviceInfoParamService(params)
                if(code === 'SUCCESS'){
                    Message.success('保存成功')
                    this.parameterStatus = false
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.parameterLoading = false
            }catch (error){
                this.parameterLoading = false
                console.log(error)
            }
        },
        /**
         * 修改工作原理
         */
        async onUpdateDeviceInfoPrinciple() {
            try {
                this.devicePrincipleEditLoading = true
                let params = {
                    equipmentId:this.deviceInfo.id,
                    principleContent:this.deviceInfoObj.principleContent,
                    detailList:this.deviceInfoObj.detailDTOList,
                    partsId:this.structuralId
                }
                const { code } = await updateDeviceInfoPrincipleService(params)
                if(code === 'SUCCESS'){
                    Message.success('修改成功')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.devicePrincipleEditLoading = false
            }catch (error){
                this.devicePrincipleEditLoading = false
                console.log(error)
            }
        },

        /**
         * 根据设备id查询当前条目基本信息
         * @returns {Promise<void>}
         */
        async onDeviceInfoIdService() {
            try {
                let params = {
                    id:this.deviceInfo.id,
                    partsId:this.structuralId
                }
                const { code,data } = await getDeviceInfoIdService(params)
                if(code === 'SUCCESS'){
                    this.SET_DEVICE_INFO(data)
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 根据结构部件id查询当前条目基本信息
         * @returns {Promise<void>}
         */
        async onStructuralInfoIdService() {
            try {
                let params = {
                    id:this.structuralId,
                }
                const { code,data } = await getStructuralInfoIdService(params)
                if(code === 'SUCCESS'){
                    this.structuralInfo = data
                    this.$forceUpdate()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 根据设备id查询当前条数据
         */
        async onDeviceInfo() {
            try {
                this.deviceInfoArray = []
                this.deviceInfoLoading = true
                let params = {
                    id:this.deviceInfo.id,
                    dataType:this.tabId,
                    partsId:this.structuralId
                }
                const { code,data } = await getDeviceInfoService(params)
                if(code === 'SUCCESS'){
                    if(this.tabId === 6){
                        this.deviceInfoObj = data
                        this.newlyParams.deviceModel = data.equipmentModelId
                        this.newlyParams.deviceName = data.name
                        this.newlyParams.deviceType = data.nodeIdList
                        this.newlyParams.files = data.equipmentPicturesUrl
                        this.newlyParams.filesUrl = data.equipmentPictures
                        if(this.structuralId===''){
                            this.onIdManufacturer().then(()=>{
                                this.newlyParams.manufacturer = data.equipmentManufacturerId
                            })
                        }else{
                            this.deviceSelectPart = data
                        }
                    }else if(this.tabId === 7 || this.tabId === 8){
                        this.deviceInfoObj = data
                        if(this.tabId === 7){
                            this.deviceInfoContent = data.introContent
                        }
                        if(this.tabId === 8){
                            for(let i in this.deviceInfoObj.detailDTOList){
                                this.deviceInfoObj.detailDTOList[i].visible = false
                                let suffixIndex = this.deviceInfoObj.detailDTOList[i].fileUrl.lastIndexOf('.')
                                let suffix=this.deviceInfoObj.detailDTOList[i].fileUrl.substring(suffixIndex+1).toUpperCase();
                                if(suffix!="BMP"&&suffix!="JPG"&&suffix!="JPEG"&&suffix!="PNG"&&suffix!="GIF"){
                                    this.deviceInfoObj.detailDTOList[i].fileFormat = 'video'
                                }else{
                                    this.deviceInfoObj.detailDTOList[i].fileFormat = 'image'
                                }
                            }

                        }
                    }else{
                        for(let i in data){
                            data[i].nameStatus = false
                            data[i].visible = false

                            if(this.tabId === 4){
                                if(data[i].childNode.length!==0){

                                    for(let j in data[i].childNode){
                                        data[i].childNode[j].visible = false
                                    }
                                }
                            }
                            if(this.tabId === 9){
                                data[i].visible = false
                                for(let j in data[i].paramDetail){
                                    data[i].paramDetail[j].nameVisible = false
                                    data[i].paramDetail[j].paramVisible = false
                                }
                            }
                        }
                        this.deviceInfoArray = data
                        console.log(this.deviceInfoArray)
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.deviceInfoLoading = false
            }catch (error){
                this.deviceInfoLoading = false
                console.log(error)
            }
        },
        /**
         * 根据设备id查询结构部件数据
         */
        async onStructuralDeviceId() {
            try {
                this.deviceStructuralArray = []
                this.deviceInfoLoading = true
                let params = {
                    id:this.deviceInfo.id,
                }
                const { code,data } = await getStructuralDeviceIdService(params)
                if(code === 'SUCCESS'){
                    for(let i in data){
                        data[i].nameStatus = false
                        data[i].visible = false

                        if(data[i].childNode.length!==0){

                            for(let j in data[i].childNode){
                                data[i].childNode[j].visible = false
                            }
                        }
                    }
                    this.deviceStructuralArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.deviceInfoLoading = false
            }catch (error){
                this.deviceInfoLoading = false
                console.log(error)
            }
        },
        /**
         * 添加资料文件
         */
        async onAddDeviceData(fileUrlList,type) {
            try {
                let params = {
                    equipmentId:this.deviceInfo.id,
                    fileUrlList:fileUrlList,
                    type:type,
                    partsId:this.structuralId
                }
                const { code } = await addDeviceDataService(params)
                if(code === 'SUCCESS'){
                    this.complete = 100
                    Message.success('添加成功！')
                    if(this.structuralId!==''){
                        this.onStructuralInfoIdService().then()
                    }else{
                        this.onDeviceInfoIdService().then()
                    }
                    this.onDeviceInfo().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除资料文件
         */
        async onDelDeviceData(id) {
            try {
                let params = {
                    idList:id
                }
                const { code } = await delDeviceDataService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功！')
                    this.deviceListParams.checkInput = []
                    if(this.structuralId!==''){
                        this.onStructuralInfoIdService().then()
                    }else{
                        this.onDeviceInfoIdService().then()
                    }
                    this.onDeviceInfo().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除电器图册文件
         */
        async onDelDeviceAtlasService(id) {
            try {
                let params = {
                    idList:id
                }
                const { code } = await delDeviceAtlasService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功！')
                    this.deviceListParams.checkInput = []
                    if(this.structuralId!==''){
                        this.onStructuralInfoIdService().then()
                    }else{
                        this.onDeviceInfoIdService().then()
                    }
                    this.onDeviceInfo().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除故障分析
         */
        async onDelFailureData(id) {
            try {
                let params = {
                    equipmentId:this.deviceInfo.id,
                    failureAnalysisIds:id,
                    partsId:this.structuralId
                }
                const { code } = await delFailureDataService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功！')
                    this.deviceListParams.checkInput = []
                    if(this.structuralId!==''){
                        this.onStructuralInfoIdService().then()
                    }else{
                        this.onDeviceInfoIdService().then()
                    }
                    this.onDeviceInfo().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除可能原因
         */
        async onDelReasonData(id) {
            try {
                let params = {
                    failureAnalysisId:this.failureAnalysisFaultId,
                    faultReasonIds:[id]
                }
                const { code } = await delReasonDataService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功！')
                    this.onDeviceInfo().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 重新上传资料文件
         * @param resourceName
         * @param id
         * @returns {Promise<void>}
         */
        async onUpdateDeviceData(id,resourceName) {
            try {
                let params =  {
                    id:id,
                    fileUrl:resourceName,
                    partsId:this.structuralId
                }

                const { code } = await updateDeviceDataService(params)
                if(code === 'SUCCESS'){
                    Message.success('添加成功！')
                    this.onDeviceInfo().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 添加故障分析
         */
        async onAddFailure() {
            try {
                this.submitLoading = true
                let params = {
                    equipmentId:this.deviceInfo.id,
                    partsId:this.structuralId,
                    faults:[
                        {
                            code:this.faultParams.code,
                            describe:this.faultParams.describe,
                            name:'',
                        }
                    ],
                }
                const { code } = await addFailureService(params)
                if(code === 'SUCCESS'){
                    Message.success('添加成功')
                    this.addFaultVisible = false
                    if(this.structuralId!==''){
                        this.onStructuralInfoIdService().then()
                    }else{
                        this.onDeviceInfoIdService().then()
                    }
                    this.onDeviceInfo().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 编辑故障分析
         */
        async onEditFailureService() {
            try {
                this.submitLoading = true
                let params = {
                    id:this.faultParams.id,
                    describe:this.faultParams.describe,
                    code:this.faultParams.code,
                    partsId:this.structuralId,
                }
                const { code } = await editFailureService(params)
                if(code === 'SUCCESS'){
                    Message.success('修改成功')
                    this.addFaultVisible = false
                    this.onDeviceInfo().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 添加可能原因
         */
        async onAddReason() {
            try {
                this.submitLoading = true
                let params = {
                    failureAnalysisFaultId:this.failureAnalysisFaultId,
                    reason:this.reasonArray.reason,
                    solutionList:this.reasonArray.solutionList,
                    partsId:this.structuralId
                }
                console.log(params)
                const { code } = await addReasonService(params)
                if(code === 'SUCCESS'){
                    Message.success('添加成功')
                    this.reasonVisible = false;
                    this.onDeviceInfo().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 编辑可能原因
         */
        async onEditReason() {
            try {
                this.submitLoading = true
                let params = {
                    id:this.reasonArray.id,
                    reason:this.reasonArray.reason,
                    solutionList:this.reasonArray.solutionList,
                    partsId:this.structuralId
                }
                const { code } = await editReasonService(params)
                if(code === 'SUCCESS'){
                    Message.success('修改成功')
                    this.reasonVisible = false;
                    this.onDeviceInfo().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 故障分析移动排序
         */
        async onFailureMoveService(id,moveType) {
            try {
                let params = {
                    id:id,
                    moveType:moveType,
                }
                const { code } = await getFailureMoveService(params)
                if(code === 'SUCCESS'){
                    this.onDeviceInfo().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 可能原因移动排序
         */
        async onReasonMoveService(id,moveType) {
            try {
                let params = {
                    id:id,
                    moveType:moveType,
                }
                const { code } = await getReasonMoveService(params)
                if(code === 'SUCCESS'){
                    this.onDeviceInfo().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 下载故障分析模版数据
         */
        async onFailureDownLoadService(id) {
            try {
                this.failureDownLoadLoading = true
                let params = {
                    equipmentId:id
                }
                const  data  = await getFailureDownLoadService(params)
                let row = {
                    flowName:'故障分析模版',
                    recordCode:onRandomPlatform(),
                }
                downloadFile(row,data,'excel')
                this.failureDownLoadLoading = false
            }catch (error){
                this.failureDownLoadLoading = false
                console.log(error)
            }
        },
        /**
         * 导出故障分析数据
         */
        async onFailureExportService(id) {
            try {
                this.failureExportLoading = true
                let params = {
                    equipmentId:id
                }
                const  data  = await getFailureExportService(params)
                let row = {
                    flowName:'故障分析数据',
                    recordCode:onRandomPlatform(),
                }
                downloadFile(row,data,'excel')
                this.failureExportLoading = false
            }catch (error){
                this.failureExportLoading = false
                console.log(error)
            }
        },
        /**
         * 导入故障分析数据
         */
        async onFailureUpLoadService(files) {
            try {
                let params =  new FormData()
                params.append('file',files)
                params.append('equipmentId',this.deviceInfo.id)
                params.append('partsId',this.structuralId)
                let config = {
                    onUploadProgress: progressEvent => {
                        this.complete = Math.round((progressEvent.loaded / progressEvent.total ) * 100) -1 ;
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                };
                this.faultsVisible = true
                const { code } = await addFailureUpLoadService(params,config)
                if(code === 'SUCCESS'){
                    this.complete = 100
                    Message.success('导入成功！')
                    if(this.structuralId!==''){
                        this.onStructuralInfoIdService().then()
                    }else{
                        this.onDeviceInfoIdService().then()
                    }
                    this.onDeviceInfo().then()
                }else{
                    Message.error('导入失败！')
                    if(code === 'IMPORT_FILE_IS_NULL'){
                        this.faultsMsg = '导入文件为空'
                    }else if(code === 'EQUIPMENT_IS_NULL'){
                        this.faultsMsg = '设备不存在'
                    }else if(code === 'EQUIPMENT_FAILURE_ANALYSIS_IS_NOT_NULL'){
                        this.faultsMsg = '故障分析已存在'
                    }else{
                        this.faultsMsg = code
                    }
                    this.faultsCancel = true
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 添加结构部件
         */
        async onAddStructural() {
            try {
                this.submitLoading = true
                let params = {
                    equipmentId:this.deviceInfo.id,
                    partsName:this.structuralParams.partsName,
                    fileUrl:this.structuralParams.resourceName,
                    partsBlurb:this.structuralParams.partsBlurb,
                    parentId:this.structuralParams.parentId,
                    partsModel:this.structuralParams.partsModel,
                }
                const { code } = await addStructuralService(params)
                if(code === 'SUCCESS'){
                    Message.success('添加成功')
                    this.structuralVisible = false
                    if(this.structuralId!==''){
                        this.onStructuralInfoIdService().then()
                    }else{
                        this.onDeviceInfoIdService().then()
                    }
                    this.onDeviceInfo().then()
                    this.onStructuralDeviceId().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 编辑结构部件
         */
        async onEditStructuralService() {
            try {
                this.submitLoading = true
                let params = {
                    id:this.structuralParams.id,
                    partsName:this.structuralParams.partsName,
                    fileUrl:this.structuralParams.resourceName,
                    partsBlurb:this.structuralParams.partsBlurb,
                    parentId:this.devicePartType,
                    partsModel:this.structuralParams.partsModel
                }
                const { code } = await editStructuralService(params)
                if(code === 'SUCCESS'){
                    Message.success('修改成功')
                    this.structuralVisible = false
                    this.onDeviceInfo().then()
                    this.onStructuralDeviceId().then()
                }else if(code === 'EQUIPMENT_PARTS_NODE_HAVE_CHILD_NODE_FAIL'){
                    Message.error('您转移的部件下存在其他部件，请移除后重试！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 删除结构部件
         */
        async onDelStructuralService(id) {
            try {
                let params = {
                    idList:id
                }
                const { code } = await delStructuralService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功！')
                    this.deviceListParams.checkInput = []
                    if(this.structuralId!==''){
                        this.onStructuralInfoIdService().then()
                    }else{
                        this.onDeviceInfoIdService().then()
                    }
                    this.onDeviceInfo().then()
                    this.onStructuralDeviceId().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 升级结构部件
         */
        async onUpgradeStructuralService(id) {
            try {
                let params = {
                    id:id
                }
                const { code } = await upgradeStructuralService(params)
                if(code === 'SUCCESS'){
                    Message.success('升级成功！')
                    this.onDeviceInfo().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * pdf保存
         */
        async onAddPdfElectricalService(resourceName,n) {
            try {
                let params = {
                    equipmentId: this.deviceInfo.id,
                    pdfFile: resourceName,
                    fileType: n,
                    partsId:this.structuralId
                }
                const { code,data } = await addPdfElectricalService(params)
                if(code === 'SUCCESS'){
                    this.pdfParams = data
                    this.pdfPageEnd = data.filePageCount
                    this.pdfPageStar = 1
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * pdf 进度查询
         */
        async onPdfElectricalProgressService(resourceName) {
            try {
                let params = {
                    fileUrl:resourceName,
                }
                const { code,data } = await getPdfElectricalProgressService(params)
                if(code === 'SUCCESS'){
                    if(data === 100){
                        this.pdfUploadStatus = false
                        this.completePdf = 100
                    }else{
                        if(data>=this.completePdf){
                            this.completePdf = data
                        }
                        setTimeout(()=>{
                            this.onPdfElectricalProgressService(resourceName).then()
                        },2000)
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * pdf转换
         */
        async onConvertPdfElectricalService() {
            try {
                let params = {
                    id: this.pdfParams.id,
                    beginPage: this.pdfPageStar,
                    endPage: this.pdfPageEnd
                }
                const { code } = await convertPdfElectricalService(params)
                if(code === 'SUCCESS'){
                    Message.success('转换成功！')
                    this.onDeviceInfo().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.pdfModalVisible = false

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 图册重命名
         */
        async onElectricalAtlasRenameService(row,index) {
            try {
                let params = {
                    id: row.id,
                    fileName:this.deviceRename
                }
                const { code } = await editElectricalAtlasRenameService(params)
                if(code === 'SUCCESS'){
                    this.deviceInfoArray[index].fileName = this.deviceRename
                    this.$forceUpdate()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
    },
    /**
     * 销毁事件
     */
    destroyed() {
        $event.$off([EVENT_NAME.REASON_MODAL_VISIBLE,EVENT_NAME.UPLOAD_PROGRESS_COMPLETE])
    }
}
export default DeviceInfo
